import { SanityImage, Subscription } from "src/sanity/types"
import cn from "classnames"
import Image from "src/components/image/Image"
import { generateSanityImage } from "src/sanity/image"
import { debounce, Heading, isMobile } from "src/utils"
import { Squircle } from "src/components/squircle/Squircle"
import s from "./SubscriptionsList.module.scss"
import { useSettings } from "../../layout/page/settingsContext"
import RichText from "../../components/rich-text/RichText"
import CallToAction, { getCallToActionTrackingHandler } from "../call-to-action/CallToAction"
import React, { SyntheticEvent, useEffect, useRef, useState } from "react"
import { getTranslation } from "src/lib/translations"
import { LinkHandler } from "../link-handler/LinkHandler"
import ButtonGroup from "../button-group/ButtonGroup"
import { Link } from "../link/Link"
import buildLink from "src/sanity/buildLink"
// import { useExperiments } from "src/layout/page/experimentsContext"

interface SubscriptionsListProps {
    subscriptions: Omit<Subscription, "benefits">[]
    showPriceYearly?: boolean
    simpleStyle?: boolean
}

export default function SubscriptionsList({ subscriptions, showPriceYearly, simpleStyle }: SubscriptionsListProps) {
    const { lang } = useSettings()
    const carouselViewEle = useRef<HTMLDivElement>()
    const [visibleIndex, setVisibleIndex] = useState(0)
    // const { activeExperiments } = useExperiments()
    // const twomonthfreesalelive = activeExperiments?.["dk_lb_2month_free_pricing_page"]?.activeVariant === "2monthfree" && segment === "private" && lang === "dk"
    const t = { ...getTranslation("subscriptionList", lang), ...getTranslation("misc", lang) }

    useEffect(() => {
        if (!carouselViewEle.current)
            return

        const observer = new IntersectionObserver(function (entries) {
            const changedEntry = entries.slice(-1)[0]
            if (changedEntry.intersectionRatio < 0.5)
                return

            const elementInFocus = changedEntry.target
            const index = Array.prototype.indexOf.call(elementInFocus.parentNode.children, elementInFocus)
            setVisibleIndex(index)
        }, {
            root: carouselViewEle.current, threshold: 0.5,
        })


        Array.prototype.slice.call(carouselViewEle.current?.children[0].children).forEach((item: HTMLLIElement) => {
            observer.observe(item)
        })


        return () => observer.disconnect()



    }, [subscriptions, carouselViewEle])

    useEffect(() => {
        if (isMobile && carouselViewEle.current) {
            // Scroll so that the highlighted subscription is in the center
            const highlightedElement = carouselViewEle.current.querySelector("[class*='subscription--highlighted'")?.parentElement?.parentElement
            if (highlightedElement) {
                const highlightedElementScrollPos = highlightedElement.offsetLeft
                const highlightedElementWidth = highlightedElement.getBoundingClientRect().width
                const carouselViewportWidth = carouselViewEle.current.getBoundingClientRect().width
                const scrollPositionToCenterHighlightedElement = (highlightedElementScrollPos - ((carouselViewportWidth - highlightedElementWidth) / 2))
                carouselViewEle.current.scrollLeft = scrollPositionToCenterHighlightedElement
                setVisibleIndex(Array.prototype.indexOf.call(highlightedElement.parentElement.children, highlightedElement))
            }
        }
    }, [])

    useEffect(() => {
        if (!carouselViewEle.current)
            return

        const onResize = debounce(() => {
            const catchphrases: HTMLParagraphElement[] = Array.prototype.slice.call(carouselViewEle.current?.querySelectorAll("[class*='subscription__catchphrase'") || [])
            catchphrases.forEach((ele) => { ele.style.height = "auto"; ele.offsetHeight })
            const maxHeight = catchphrases.reduce((maxValue, currentEle) => Math.max(maxValue, currentEle.offsetHeight), 0)
            catchphrases.forEach((ele) => ele.style.height = maxHeight + "px")
        }, 300)
        onResize()

        window.addEventListener("resize", onResize)

        return () => {
            window.removeEventListener("resize", onResize)
        }

    }, [])

    return (
        <>
            <div className={s["subscriptions-list"]} ref={carouselViewEle}>
                <ul className={cn(s["subscriptions-carousel"])} style={{ "--subscription-count": subscriptions.length } as React.CSSProperties}>
                    {subscriptions?.map((sub, i) => {
                        const images: { image: SanityImage, main?: boolean }[] = [
                            ...(sub.cardImageAlternatives || []),
                            ...(sub.cardImage?.type === "image" && sub.cardImage?.src ? [{ image: sub.cardImage, main: true }] : []),
                        ]

                        const isHighlighted = ["Plus", "Essential"].includes(sub.title)

                        const sale = 0

                        // if (sub.title === "Standard" && twomonthfreesalelive)
                        //     sale = 1

                        let beforePrice
                        if (showPriceYearly) {
                            beforePrice = parseInt(sub.price)
                            if (sale > 0 && beforePrice > 0)
                                beforePrice = parseInt(sub.priceYearly) / 12
                        }
                        if (sale > 0 && !showPriceYearly)
                            beforePrice = parseInt(sub.price)

                        beforePrice = Math.ceil(beforePrice)

                        let price = showPriceYearly ?
                            parseInt(sub.priceYearly.replaceAll(/[^\d]/g, "")) * (1 - sale) / 12
                            : parseInt(sub.price) * (1 - sale)

                        price = Math.round(price)

                        const getAppCta = sub.getAppLink

                        return (
                            <li className={s["subscriptions-carousel__item"]} key={sub.id + i}>
                                <LinkHandler link={sub.getAppLink.link} onMouseDown={getCallToActionTrackingHandler(sub.getAppLink.link)}>
                                    <div className={cn(s["subscription"], isHighlighted ? s["subscription--highlighted"] : null)}>

                                        {/* Tophat badge */}
                                        {["dk", "en", "se"].includes(lang) && ["Essential", "Limitless", "Plus"].includes(sub.title) && (
                                            <Squircle cornerRadius={0.01} topLeftCornerRadius={12} topRightCornerRadius={12} as="div" className={cn(s["subscription__card__tophat"], "label-100", "light-font-weight")}>
                                                {/* Most Popular (Plus and Essential) */}
                                                {sub.title === "Plus" ? t.mostPopular : ""}
                                                {sub.title === "Essential" ? (t.mostPopularBusiness + " + ") : ""}
                                                {/* No startup fee (Essential and Limitless) */}
                                                {["Essential", "Limitless"].includes(sub.title) ? t.noStartupFee : ""}
                                            </Squircle>
                                        )}

                                        <Squircle cornerRadius={12} as="div" className={s["subscription__card"]}>
                                            <Heading className={cn(s["subscription__title"], "heading-200")}>
                                                {sub.title}
                                            </Heading>
                                            <p className={cn(s["subscription__price"], "heading-100")}>
                                                {beforePrice > 0 && (
                                                    <CrossedOutNumber number={beforePrice} />
                                                )}
                                                <span>{price}</span>
                                                <span className="light-font-weight"> {sub.price.split(" ")[1]} {t.pricePerMonthShort}</span>
                                            </p>
                                            {sub.priceYearly && (
                                                <span className={cn(s["subscription__price__yearly-savings"], s["subscription__price__yearly-savings--yearly-toggle"])}>
                                                    {showPriceYearly ? (
                                                        `${t.paidYearly} (${sub.priceYearly})`
                                                    ) : (
                                                        t.yearlyPaymentDisclaimer
                                                        // t.saveXByPayingXYearly
                                                        //     .replace("%s", "" + Math.ceil(((parseInt(sub.price) - (parseInt(sub.priceYearly) / 12)) / parseInt(sub.price)) * 100) + "%")
                                                    )}
                                                    {["dk", "en", "se"].includes(lang) && sub.title === "Simple" ? (". " + t.b2bSimpleTierStartupFee) : (". " + t.noStartupFee)}
                                                </span>
                                            )}

                                            <p className={cn(s["subscription__catchphrase"], "paragraph-200")}>
                                                {sub.description.slice(0, 1)?.[0]?.children?.[0]?.text}
                                            </p>

                                            {simpleStyle !== true && (
                                                <>
                                                    <ButtonGroup className={cn(s["subscription__cta-group"])}>
                                                        <CallToAction link={getAppCta.link} style={getAppCta.style} appearance={isHighlighted ? "dark" : null} className={cn(s["subscription__primary-cta"])} />
                                                        {sub.page && (
                                                            <Link
                                                                href={buildLink(sub.page)}
                                                                onClick={(e: SyntheticEvent) => e.stopPropagation()}
                                                                arrow
                                                            >
                                                                {t.seeMore}
                                                            </Link>
                                                        )}
                                                    </ButtonGroup>
                                                    <RichText blocks={sub.description.slice(1)} className={cn(s["subscription__bullets"], "paragraph-100")} />
                                                    {images.length > 0 && (
                                                        <div
                                                            className={s["subscription__image"]}
                                                        >
                                                            {images.map((j, index) => {
                                                                const curve = (index - ((images.length - 1) / 2))

                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        className={cn(s["subscription__image__inner"], {
                                                                            [s["subscription__image__inner--alt"]]: !j.main,
                                                                        })}
                                                                        style={{
                                                                            "--x": `${curve * 64}px`,
                                                                            "--rotation": `${curve * 8}deg`,
                                                                        } as React.CSSProperties}
                                                                    >
                                                                        <Image
                                                                            {...generateSanityImage({
                                                                                image: j.image,
                                                                                width: j.image.width,
                                                                                height: j.image.height,
                                                                            })}
                                                                            alt=""
                                                                            sizes={["(max-width: 768px) 100vw", "25vw"]}
                                                                        />
                                                                    </div>
                                                                )
                                                            })}

                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </Squircle>
                                    </div>
                                </LinkHandler>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className={cn(s["subscription__pagination"])}>
                {subscriptions?.map((_, i) => (
                    <div key={i} className={cn(s["subscription__pagination__indicator"], { [s["subscription__pagination__indicator--active"]]: visibleIndex === i })}></div>
                ))}
            </div>
        </>
    )
}

function CrossedOutNumber({ number }: { number: number }) {
    return (
        <span className={cn(s["subscription__price--strikethrough"])}>
            {number}
            <svg style={{ width: "100%", height: "100%" }}>
                <line x1="0" y1="90%" x2="100%" y2="10%"
                    style={{ stroke: "currentColor", strokeWidth: "2" }} />
            </svg>
        </span>
    )
}
